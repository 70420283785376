




























import { Component, Vue } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FormDeFechamentoDeCaixa } from '@/models'
import { SaveFechamentoDeCaixaUseCase } from '@/usecases'

@Component
export default class SnackbarDeAuditoria extends Vue {
	fechamento: FormDeFechamentoDeCaixa | null = null
	mostra = false
	indiceAtualizado = -1

	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()

	mostrar(indice: number, fechamentoDeCaixa: FormDeFechamentoDeCaixa) {
		this.mostra = true
		this.fechamento = fechamentoDeCaixa
		this.indiceAtualizado = indice
	}

	async desfazerAuditoria() {
		if (!this.fechamento) return
		try {
			this.fechamento.situacaoAudicao = 'Pendente'
			const fechamentoDeCaixaAtualizado = await this.saveFechamentoDeCaixaUseCase.update(this.fechamento)
			this.$emit('desfazer', this.indiceAtualizado, fechamentoDeCaixaAtualizado)
			AlertModule.setSuccess('Auditoria desfeita com sucesso!')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.mostra = false
		}
	}
}

