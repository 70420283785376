






































import { Component,  Vue, Prop } from 'vue-property-decorator'
import { TotalPorBandeiraDoFechamento } from '@/models/venda/FechamentoDeCaixa'
import { Inject } from 'inversify-props'
import { FechamentoDeCaixaServiceAdapter } from '@/usecases'
import { formatarMoeda } from '@/shareds/formatadores'

@Component
export default class DialogoDePagamento extends Vue {
	@Prop({ type: String }) idFechamento!: string

	@Inject('FechamentoDeCaixaServiceAdapter')
	private fechamentoDeCaixaServiceAdapter!: FechamentoDeCaixaServiceAdapter

	totaisPorBandeira: TotalPorBandeiraDoFechamento[] = []

	formatarMoeda = formatarMoeda

	carregando = true
	erroAoBuscar: Error | null = null

	created() {
		this.buscarTotais()
	}

	async buscarTotais() {
		try {
			this.carregando = true
			this.totaisPorBandeira = await this.fechamentoDeCaixaServiceAdapter.getTotaisPorBandeira(this.idFechamento)
		} catch (error) {
			this.erroAoBuscar = error
		} finally {
			this.carregando = false
		}
	}
}
