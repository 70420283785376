

































































































import { Vue, Component, Ref, PropSync } from 'vue-property-decorator'
import { FechamentoDeCaixaDetalhado, ItemDeFechamento } from '@/models'
import { formatarMoeda } from '@/shareds/formatadores'
import { downloadFilesFromS3 } from '@/shareds/s3/files'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Inject } from 'inversify-props'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { FechamentoDeCaixaServiceAdapter } from '@/usecases'

@Component({
	components: {
		CampoDecimal,
	},
})
export default class TabelaDeFechamento extends Vue {
	@PropSync('value', { type: Object}) fechamento!: FechamentoDeCaixaDetalhado

	@Inject('FechamentoDeCaixaServiceAdapter')
	fechamentoDeCaixaService!: FechamentoDeCaixaServiceAdapter

	@Ref() formDeEdicaoDoValorContado!: HTMLFormElement
	@Ref() campoDeTotalEsperado!: HTMLInputElement

	baixandoAnexos = false
	formatarMoeda = formatarMoeda

	itemEmEdicao: ItemDeFechamento | null = null

	get totalEsperado() {
		const total = this.fechamento.itensDeFechamento.reduce(
			(total, { valorEsperado }) => total + valorEsperado,
			0,
		)
		return total
	}

	enviando = false
	async confirmaEdicao() {
		if (!this.itemEmEdicao) return
		if (this.enviando) return
		try {
			this.enviando = true
			const itemAtualizado = await this.fechamentoDeCaixaService.atualizaItemDeFechamento(this.itemEmEdicao.id, {
				valorContado: this.itemEmEdicao.valorContado,
			})
			const indice = this.fechamento.itensDeFechamento.findIndex(
				item => item.id === itemAtualizado.id,
			)
			if (indice == -1) return
			this.fechamento.itensDeFechamento.splice(indice, 1, itemAtualizado)
			const totalContado = this.fechamento.itensDeFechamento.reduce(
				(total, { valorContado }) => total + valorContado,
				0,
			)
			const fechamentoAtualizado: FechamentoDeCaixaDetalhado = {
				...this.fechamento,
				totalContado,
				totalDeDiferenca: totalContado - this.fechamento.totalEsperado,
			}
			this.$emit('input', fechamentoAtualizado)
			this.itemEmEdicao = null
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.enviando = false
		}
	}

	get totalContado() {
		const total = this.fechamento.itensDeFechamento.reduce(
			(total, { valorContado }) => total + valorContado,
			0,
		)
		return total
	}

	get totalDiferenca() {
		const total = this.fechamento.itensDeFechamento.reduce(
			(total, { valorContado, valorEsperado }) =>
				total + valorContado - valorEsperado,
			0,
		)
		return total
	}

	editarValorContado(item: ItemDeFechamento) {
		this.itemEmEdicao = JSON.parse(JSON.stringify(item))
		window.requestAnimationFrame(() => {
			this.formDeEdicaoDoValorContado.resetValidation()
			window.requestAnimationFrame(() => {
				this.campoDeTotalEsperado.focus()
			})
		})
	}

	baixarArquivo(caminhoDaPasta: string, nomeDoArquivo: string) {
		try {
			this.baixandoAnexos = true
			downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
		} catch (error) {
			AlertModule.setError('Houve um problema ao baixar o arquivo')
		} finally {
			this.baixandoAnexos = false
		}
	}
	classDiferenca(diferenca: number) {
		if (diferenca < 0) {
			return ['error--text']
		} else {
			return ['success--text']
		}
	}
}

