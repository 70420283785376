













































import { Vue, Component, Ref } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FormDeFechamentoDeCaixa } from '@/models'
import { obrigatorio } from '@/shareds/regras-de-form'
import { SaveFechamentoDeCaixaUseCase } from '@/usecases'

@Component
export default class DialogoDeAuditoria extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() campoStatus!: HTMLInputElement
	
	fechamento: FormDeFechamentoDeCaixa | null = null
	mostra = false
	salvando = false
	obrigatorio = obrigatorio

	saveFechamentoDeCaixaUseCase = new SaveFechamentoDeCaixaUseCase()

	async salvar() {
		if (!this.fechamento) return
		if (!this.form.validate()) return
		try {
			this.salvando = true

			const fechamentoDeCaixaAtualizado = await this.saveFechamentoDeCaixaUseCase.update(this.fechamento)
			this.$emit('confirmado', fechamentoDeCaixaAtualizado)
			AlertModule.setSuccess('Fechamento do caixa auditado com sucesso!')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.mostra = false
			this.salvando = false
		}
	}

	mostrar(fechamento: FormDeFechamentoDeCaixa) {
		this.fechamento = JSON.parse(JSON.stringify(fechamento))
		this.mostra = true
		setTimeout(() => {
			this.form.resetValidation()
		})
	}

	fechar() {
		this.mostra = false
	}
}
